import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../components/layout';
import ContactBlock from '../components/contactBlock';
import JobsBlock from '../components/jobsBlock';
import Footer from '../components/footer';

import { ThemeContext } from '../context/theme-context';
import CarouselImage from '../components/carouselImage';

import imgData from '../data/sobre-nos.json';

const CulturaPage = ({ location }) => {

    return (
      <Layout bg="black" location={location}>
        <Helmet>
          <title>ATUO - Sobre Nós</title>
        </Helmet>

        <div className="container">
          <div className="size-hero grid content-center py-60 md:py-80">
            <h1 className="text-7xl md:text-9xl">sobre nós</h1>
          </div>

          <div className="mb-40 md:grid grid-cols-3 grid-row-2">
            <div className="col-span-2 row-span-1 mr-0 md:w-fit md:mr-7">
              <h2 className="text-4xl md:text-8xl">
                Somos: ousados, curiosos, inquietos, independentes e inspirados
                por pessoas.
              </h2>
            </div>
            <div className="col-start-3 row-start-2 flex-1 mt-12 md:mt-0">
              <p className="text-xl font-light">
                Contamos com metodologia própria e estudos contínuos que
                garantem inovação. Criamos experiências que provocam os sentidos
                e ressaltam o melhor de nossos clientes.
              </p>
            </div>
          </div>

          <div className="mb-40 flex justify-center items-center relative">
            <img
              className="md:my-40 md:py-40 lollipop transform"
              src={`cultura/lollipop_t20_VoB43l.png`}
              alt=""
            />
            <div className="absolute right-0">
              <p className="text-4xl md:text-8xl text-right">
                Indies por <br /> ousadia, <br /> rebeldes por <br /> convicção
              </p>
            </div>
          </div>

          <div className="my-40 mt-60 md:my-60">
            <p className="text-2xl md:text-3xl font-light md:leading-normal text-center md:px-36">
              Indie é liberdade. É ter ideias e convicções próprias. Rebeldia é
              revolucionar. É quebrar regras.
            </p>
          </div>

          <div className="mb-24 md:mb-40">
            <CarouselImage>
              {imgData.map(el => (
                <div className="item">
                  <img src={el.img} alt="" />
                </div>
              ))};
            </CarouselImage>
          </div>
        </div>

        <div className="container">
          <p className="text-3xl font-light text-center leading-relaxed my-40 md:px-60 md:my-60">
            Somos sérios e experientes em nossas estratégias e metodologias.
            Seguimos nossas convicções e confiamos em nossa tecnologia e em
            nossa expertise. Mas quando o assunto é ousadia e criatividade...
            ah, aí deixamos a rebeldia tomar conta e ousamos sem pedir licença.
            O resultado? Criação de marcas e conceitos únicos.
          </p>
        </div>

        <ThemeContext.Consumer>
          {(cx) => <ContactBlock weather={cx?.cookies?.weather} />}
        </ThemeContext.Consumer>

        <JobsBlock />

        <Footer bg="black" />
      </Layout>
    );
};

export default CulturaPage;
